import React from "react";
import { Box, Typography } from "@mui/material";
import { Dialog, CustomTabPanel, TabsSection } from "@packages/theme-mui-v5";
import PermissionsList from "./PermissionsList";
import PreferencesPanel from "./PreferencesPanel";
import SitePanel from "./SitePanel";
import ImpersonateUser from "./ImpersonateUser";
import { impersonateUserPermissionId } from "../../config";
import { Auth, useUserProfile } from "@packages/service-api";
export type UserAccountTab =
  | "Account"
  | "Site"
  | "Permissions"
  | "Preferences"
  | "Impersonate User";

type Props = {
  defaultTab?: UserAccountTab;
  onClose: () => void;
};

const UserProfileModal: React.FC<Props> = ({ defaultTab, onClose }) => {
  const profile = useUserProfile();

  const fullName = `${profile.firstName} ${profile.lastName}`;
  const renderLabelandValue = (label: string, value: string) => (
    <Box pb={2}>
      <Typography>
        <b>{label}</b>
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
  const Account = () => {
    return (
      <Box py={2}>
        {renderLabelandValue("Name", fullName)}
        {renderLabelandValue("User Name", profile.userName)}
        {renderLabelandValue("Email Address", profile.email)}
      </Box>
    );
  };
  const config = React.useMemo(() => {
    return [
      {
        label: "Account",
        value: "Account",
        TabComponent: Account,
        show: true
      },
      {
        label: "Site",
        value: "Site",
        TabComponent: SitePanel,
        show: true
      },
      {
        label: "Permissions",
        value: "Permissions",
        TabComponent: PermissionsList,
        show: true
      },
      {
        label: "Preferences",
        value: "Preferences",
        TabComponent: PreferencesPanel,
        show: true
      },
      {
        label: "Impersonate User",
        value: "Impersonate User",
        TabComponent: ImpersonateUser,
        show: profile.permissions.some(
          (permission) =>
            Auth.isImpersonationActive ||
            impersonateUserPermissionId === permission.permissionTypeId
        )
      }
    ]
      .filter((item) => item.show)
      .map((data, i) => ({ ...data, index: i }));
  }, [profile]);

  const defaultTabConfig = config.find((item) => item.value === defaultTab);
  const [activeTab, setActiveTab] = React.useState<number>(
    defaultTabConfig ? defaultTabConfig.index : 0
  );

  const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
    const data = config.find((item) => item.index === newTab);
    setActiveTab(data.index);
  };

  const tabConfig = React.useMemo(() => {
    return config.map((item) => item.label);
  }, [config]);

  return (
    <Dialog title={fullName} onClose={onClose}>
      <Box>
        <TabsSection tabState={activeTab} tabs={tabConfig} handleTabChange={handleTabChange} />

        {config.map(({ TabComponent, index, value }) => (
          <CustomTabPanel key={index} value={activeTab} index={index}>
            <TabComponent key={value} />
          </CustomTabPanel>
        ))}
      </Box>
    </Dialog>
  );
};

export default UserProfileModal;
